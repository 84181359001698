@import 'reset/reset.css';
html {
  font-weight: bold;
  color: #fff;
  padding: 30px;
  height: auto;
}
header {
  text-align: center;
  margin: 30px 0 60px;
}
div {
  height: 100px;
  line-height: 100px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
div div {
  margin-top: 0;
  margin-bottom: 0;
}
section {
  lost-center: 1140px;
  margin-top: 30px;
  margin-bottom: 30px;
}
section:last-of-type {
  margin-bottom: 0;
}
section:nth-of-type(1) {
  background: #42cafd;
}
section:nth-of-type(1) div {
  lost-column: 1/1;
  background: #22c1fd;
}
@media (min-width: 500px) {
  section:nth-of-type(1) div {
    lost-column: 1/2;
  }
}
@media (min-width: 800px) {
  section:nth-of-type(1) div {
    lost-column: 1/3;
  }
}
@media (min-width: 1100px) {
  section:nth-of-type(1) div {
    lost-column: 1/4;
  }
}
section:nth-of-type(2) {
  background: #66b3ba;
}
section:nth-of-type(2) div {
  lost-column: 1/3;
  background: #52a9b1;
}
section:nth-of-type(2) div:first-child {
  lost-offset: 1/3;
}
section:nth-of-type(3) {
  background: #8eb19d;
}
section:nth-of-type(3) div {
  lost-column: 1/3;
  background: #7ba48d;
}
section:nth-of-type(3) div div {
  lost-column: 1/2;
  background: #68977c;
}
section:nth-of-type(4) {
  lost-align: center;
  background: #f0d2d1;
  height: 300px;
}
section:nth-of-type(4) div {
  lost-column: 1/3;
  background: #e5b1af;
  margin-top: 0;
  margin-bottom: 0;
}
section:nth-of-type(5) {
  background: #f19a3e;
}
section:nth-of-type(5) div {
  lost-column: 1/1;
  height: auto;
  line-height: 25px;
  font-weight: normal;
  padding: 15px;
  background: #ef8b22;
}
@media (min-width: 500px) {
  section:nth-of-type(5) div {
    lost-column: 2/4 2;
  }
}
@media (min-width: 800px) {
  section:nth-of-type(5) div {
    lost-column: 2/6 3;
  }
}
@media (min-width: 1100px) {
  section:nth-of-type(5) div {
    lost-column: 2/8 4;
  }
}
section:nth-of-type(6) {
  background: #1e90ff;
}
section:nth-of-type(6) div {
  lost-row: 1/3;
  margin-top: 0;
  background: #0182ff;
  padding: 30px;
}
section:nth-of-type(7) {
  background: #ff6347;
}
section:nth-of-type(7) div {
  lost-waffle: 1/3;
  margin-top: 0;
  background: #ff4726;
  line-height: inherit;
  padding: 30px;
}
section:nth-of-type(8) {
  background: #00ff7f;
}
section:nth-of-type(8) div {
  background: #00e672;
}
section:nth-of-type(8) div:first-child {
  lost-column: 1/3;
}
section:nth-of-type(8) div:last-child {
  lost-column: 2/3;
}
section:nth-of-type(9) {
  background: #5f9ea0;
}
section:nth-of-type(9) div {
  background: #568e90;
  lost-column: 1/2;
}
@media (min-width: 800px) {
  section:nth-of-type(9) div:first-child {
    lost-move: 1/2;
  }
  section:nth-of-type(9) div:last-child {
    lost-move: -1/2;
  }
}
